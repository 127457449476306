import { useTranslation } from 'next-i18next';

import { cn } from '@/lib/utils';

interface DisclaimerProps {
  className?: string;
}

const Disclaimer = ({ className }: DisclaimerProps) => {
  const { t } = useTranslation();

  return (
    <div className={cn(`pt-8 text-center max-md:pt-6`, className)}>
      <div className={`text-sm text-gray-500`}>
        {t(
          'Dolphin Radar is not affiliated with Instagram™. We do not host any Instagram content. All rights belong to their respective owners.',
        )}
      </div>
      <div className={`text-sm text-gray-500`}>
        {t('We respect privacy — only publicly available content is accessible.')}
      </div>
    </div>
  );
};

export default Disclaimer;
