import React, { useEffect, useMemo, useRef, useState } from 'react';
import styles from './index.module.less';
import { useTranslation } from 'next-i18next';
import { Button } from 'antd';
import Image from 'next/image';
import { useWindowSize } from 'react-use';
import { HeroData, SearchLimitLeftModel } from '@/types/home';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { cn } from '@/lib/utils';
import Disclaimer from '../Disclaimer';

interface TopSearchContentProps {
  hideSearch?: boolean;
  inputPlaceholder?: string;
  showSearchBtnWithFullWidth?: boolean;
  btnPlaceholder?: string;
  displayTagsAndMiddleContentBelowSearchBox?: boolean;
  topInfo?: HeroData;
  middleContent?: React.ReactNode;
  loading?: boolean;
  leftModel?: SearchLimitLeftModel;
  searchTabs?: {
    label: string | React.ReactNode;
    value: string;
  }[];
  defaultSearchTab?: string;
  onSearchTabClick?: (value: string) => void;
  searchVal?: string;
  onSearchClick?: (searchStr?: string) => void;
  onValueChanged?: (e?: string) => void;
  onInputVisibleChanged?: (visible?: boolean) => void;
  onUnlimitedClick?: () => void;
  errorCode?: string | number;
  resetErrorCode?: () => void;
}

const TopSearchContent = ({
  hideSearch = false,
  inputPlaceholder,
  showSearchBtnWithFullWidth,
  btnPlaceholder,
  displayTagsAndMiddleContentBelowSearchBox,
  topInfo,
  middleContent,
  loading,
  leftModel,
  searchVal,
  onSearchClick,
  searchTabs,
  defaultSearchTab,
  onSearchTabClick,
  onValueChanged,
  onInputVisibleChanged,
  onUnlimitedClick,
  errorCode,
  resetErrorCode,
}: TopSearchContentProps) => {
  const { t } = useTranslation();
  const [searchStr, setSearchStr] = useState(searchVal);
  const inputRef = useRef(null);
  const { width } = useWindowSize();
  const router = useRouter();

  useEffect(() => {
    const handleScroll = () => {
      if (inputRef.current) {
        const rect = inputRef.current.getBoundingClientRect();
        onInputVisibleChanged &&
          onInputVisibleChanged(rect.top >= 0 && rect.bottom <= window.innerHeight);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (searchVal !== searchStr) {
      setSearchStr(searchVal);
    }
  }, [searchVal]);

  useEffect(() => {
    if (errorCode) {
      setSearchStr('');
    }
  }, [errorCode]);

  const list = useMemo(() => {
    const tempList = topInfo?.tags;
    return width > 768 ? tempList : tempList?.slice(0, Math.min(2, tempList.length));
  }, [width, topInfo?.tags]);

  const getTimeUntilUTCMidnight = () => {
    const now = new Date();
    const utcNow = new Date(now.getTime() + now.getTimezoneOffset() * 60000);
    const tomorrow = new Date(utcNow);
    tomorrow.setUTCHours(24, 0, 0, 0);

    const diffMs = tomorrow.getTime() - utcNow.getTime();
    const hours = Math.floor(diffMs / (1000 * 60 * 60));
    const minutes = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60));

    return `${hours}h ${minutes}m`;
  };

  const timesContent = useMemo(() => {
    if (!leftModel || leftModel?.un_limit) {
      return null;
    }

    return (
      <div className={styles.timesDesc}>
        <div className={styles.item}>
          <span>{t('Free Viewer limit:')}</span>
          <span style={{ color: leftModel?.search_limit > 0 ? '#24A029' : '#FF6464' }}>
            {leftModel?.search_limit ?? 0}/1
          </span>
        </div>
        <div className={styles.item}>
          <span>{t('Free download limit:')}</span>
          <span style={{ color: leftModel?.download_limit > 0 ? '#24A029' : '#FF6464' }}>
            {leftModel?.download_limit ?? 0}/3
          </span>
        </div>
        <div className={styles.item}>
          <Image src={'/images/landingpage/down_time_icon.webp'} alt={''} width={16} height={16} />
          <span>
            {t('Updated in')} {getTimeUntilUTCMidnight()}
          </span>
        </div>
        <div className={styles.item}>
          <span style={{ color: '#725CFF', cursor: 'pointer' }} onClick={onUnlimitedClick}>
            {t('Get unlimited')}
          </span>
        </div>
      </div>
    );
  }, [leftModel]);

  const isShow = useMemo(() => {
    const arr = [
      '/view-instagram-anonymously/stories',
      '/download-from-instagram',
      '/download-from-instagram/story',
      '/download-from-instagram/highlights',
      '/download-from-instagram/post',
      '/instagram-viewer',
      '/instagram-viewer/posts',
      '/instagram-viewer/stories',
      '/instagram-viewer/highlights',
      '/anonymous-instagram-story-viewer',
      '/web-viewer-for-instagram',
    ];
    return arr.includes(router.asPath);
  }, [router.asPath]);

  const isShowSampleReport = useMemo(() => {
    const arr = [
      '/',
      '/view-instagram-anonymously',
      '/view-instagram-anonymously/users-search',
      '/view-instagram-anonymously/comments',
      '/instagram-tracker',
      '/instagram-tracker/follower-tracker',
      '/instagram-tracker/activity-tracker',
      '/instagram-tracker/see-someones-likes',
      '/instagram-influencer-trends',
      '/instagram-influencer-trends/track-activity',
      '/instagram-influencer-trends/uncover-gossip',
      '/catch-instagram-cheaters',
      '/catch-instagram-cheaters/track-cheating-activity',
    ];
    return arr.includes(router.asPath);
  }, [router.asPath]);

  const renderBasicContent = () => {
    return (
      <>
        {topInfo?.title && (
          <h1 className={styles.title} style={topInfo.titleStyle}>
            {topInfo?.title}
          </h1>
        )}

        {topInfo?.subTitle && (
          <p className={styles.subTitle} style={topInfo.subTitleStyle}>
            {topInfo?.subTitle}
          </p>
        )}

        {topInfo?.desc && (
          <p className={styles.subDesc} style={topInfo.descStyle}>
            {topInfo?.desc}
          </p>
        )}
      </>
    );
  };

  const renderTags = () => {
    return (
      list?.length > 0 && (
        <div
          className={styles.list}
          style={{
            gridTemplateColumns: `repeat(${list?.length >= 4 ? 4 : list?.length}, 1fr)`,
            ...topInfo.tagsStyle,
          }}
        >
          {list.map((item, index) => {
            return (
              <div key={index} className={styles.listItem}>
                <Image src={'/images/home/home-select-icon.webp'} alt="" width={17} height={12} />
                <p dangerouslySetInnerHTML={{ __html: item }} />
              </div>
            );
          })}
        </div>
      )
    );
  };

  const renderSearchTabs = () => {
    return (
      <div
        className="h-12 mt-10 flex items-center cursor-pointer overflow-hidden rounded-t-xl"
      >
        {searchTabs?.map((item) => (
          <div
            key={item.value}
            className={cn(
              'flex-1 flex justify-center items-center h-full hover:bg-[#ECEDF4] transition-colors',
              {
                'text-[#725CFF] bg-[#ECEDF4]': item.value === defaultSearchTab,
              },
            )}
            onClick={() => {
              onSearchTabClick?.(item.value);
            }}
          >
            {item.label}
          </div>
        ))}
      </div>
    );
  };

  const renderSearch = () => {
    return (
      <div
        className={cn({
          'p-4 bg-[#ECEDF4] rounded-b-xl': !!searchTabs,
        })}
      >
        <div
          className={cn(styles.inputContainer, 'w-[520px] pl-4 py-2 pr-2 bg-white', {
            'mt-10': !searchTabs,
            'w-full': !!searchTabs,
          })}
          ref={inputRef}
        >
          <Image src={'/images/home/instagram-icon.webp'} alt="instagram" width={24} height={24} />
          <input
            value={searchStr}
            onChange={(e) => {
              setSearchStr(e.target.value);
              onValueChanged && onValueChanged(e?.target?.value);
            }}
            onKeyDown={(e) => {
              if (e?.key === 'Enter') {
                const value = (e.target as HTMLInputElement).value;
                onSearchClick(value);
              }
            }}
            onFocus={() => {
              if (errorCode) {
                resetErrorCode && resetErrorCode();
              }
            }}
            placeholder={inputPlaceholder ?? t('insert link or username')}
          />
          <Button
            loading={loading}
            className={cn(styles.searchBtn, {
              'max-md:hidden': showSearchBtnWithFullWidth,
            })}
            onClick={() => {
              onSearchClick(searchStr);
            }}
          >
            {btnPlaceholder ?? t('Search Now')}
          </Button>
        </div>
        <Button
          loading={loading}
          className={cn(styles.searchBtnWithFullWidth, {
            hidden: true,
            'max-md:block': showSearchBtnWithFullWidth,
          })}
          onClick={() => {
            onSearchClick(searchStr);
          }}
        >
          {btnPlaceholder ?? t('Search Now')}
        </Button>
        {!!errorCode && (
          <div className={styles.error}>
            <span>{t('ThisInstagramAccountIsPrivate_Desc')}</span>
          </div>
        )}
      </div>
    );
  };

  if (!topInfo) {
    return null;
  }

  return (
    <div className={styles.content}>
      {/* 基础内容 */}
      {renderBasicContent()}

      {/* 标签和自定义的中间内容，搜索框之前显示 */}
      {!displayTagsAndMiddleContentBelowSearchBox && (
        <>
          {renderTags()}
          {middleContent}
        </>
      )}

      {/* 搜索选项卡 */}
      {searchTabs && renderSearchTabs()}

      {/* 搜索框 */}
      {!hideSearch && renderSearch()}

      {/* 标签和自定义的中间内容，搜索框之后显示 */}
      {displayTagsAndMiddleContentBelowSearchBox && (
        <>
          {renderTags()}
          {middleContent}
        </>
      )}

      {/* 示例报告入口 */}
      {isShowSampleReport && (
        <div className="mt-8">
          {t('See what our reports offer')} -{' '}
          <Link className="text-[#725CFF]" href="/instagram-tracking-example">
            {t('Sample here')}
          </Link>
        </div>
      )}

      {/* {(times !== -1) && <p className={styles.timesDesc}>
        <span style={{ color: '#24A029', paddingRight: '5px' }}>{times}/3</span>
        <span>{t('Free Searches Remaining')}</span>
      </p>} */}

      {timesContent}
      <Disclaimer className={`${!isShow ? 'hidden' : ''}`} />
    </div>
  );
};

export default TopSearchContent;
